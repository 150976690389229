import axios from 'axios'

const http = axios.create({
  baseURL: 'https://sit.app-admin.seehay.com/seehay/api/v1',
  // baseURL: 'http://localhost:3003/seehay/api/v1', // localhost
  headers: {
    Authorization: 'Bearer mock/a0a25e04-51e9-42c7-851d-7606ffc59e7f'
  }
  // baseURL: '/seehay/api/v1'
})
http.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 403) {
      window.location.href = '/seehay/api/v1/oauth2/login'
    }

    if (error.response.status === 404) {
      console.log('baseURL is not found on this server!')
    }

    return Promise.reject(error)
  }
)

export default http
