<template>
  <nav class="sidenav shadow-sm">
    <div class="sidenav-menu">
      <div class="nav">
        <router-link class="nav-link" to="/dashboard">
          <div class="nav-link--icon">
            <img src="../assets/apps.png" alt="" width="48" height="48">
          </div>
          <div class="nav-link--title">儀表板</div>
        </router-link>

        <router-link class="nav-link" to="/dispatcher/PLACED">
          <div class="nav-link--icon">
            <img src="../assets/receipt_3974084.png" alt="" width="40" height="40">
          </div>
          <div class="nav-link--title">訂單管理</div>
        </router-link>

        <router-link class="nav-link" to="/category">
          <div class="nav-link--icon">
            <IconCategory />
          </div>
          <div class="nav-link--title">產品系列</div>
        </router-link>

        <router-link class="nav-link" to="/product">
          <div class="nav-link--icon">
            <IconProduct />
          </div>
          <div class="nav-link--title">產品管理</div>
        </router-link>

        <router-link class="nav-link" to="/user-management">
          <div class="nav-link--icon">
            <img src="../assets/group_user.png" alt="user-management" width="40" height="40">
          </div>
          <div class="nav-link--title">使用者管理</div>
        </router-link>

        <router-link class="nav-link" to="/product-marketing">
          <div class="nav-link--icon">
            <img src="../assets/product_marketing.png" alt="" width="42" height="42">
          </div>
          <div class="nav-link--title">產品推廣</div>
        </router-link>

        <router-link class="nav-link" to="/feed">
          <div class="nav-link--icon">
            <IconFeed />
          </div>
          <div class="nav-link--title">珠寶資訊</div>
        </router-link>

        <router-link class="nav-link" to="/gold-news">
          <div class="nav-link--icon">
            <IconGoldNews />
          </div>
          <div class="nav-link--title">飾金金價</div>
        </router-link>

        <router-link class="nav-link" to="/loyalty-programs">
          <div class="nav-link--icon">
            <img src="../assets/voucher_5250057.png" alt="" width="40" height="40">
          </div>
          <div class="nav-link--title">優惠券管理</div>
        </router-link>

        <router-link class="nav-link" to="/branch">
          <div class="nav-link--icon">
            <IconBranch />
          </div>
          <div class="nav-link--title">分店</div>
        </router-link>

        <router-link class="nav-link" to="/rmbrate">
          <div class="nav-link--icon">
            <IconRmbrate />
          </div>
          <div class="nav-link--title">貨幣匯率</div>
        </router-link>

        <router-link class="nav-link" to="/settings">
          <div class="nav-link--icon">
            <img src="../assets/settings_10947994.png" alt="" width="42" height="42">
          </div>
          <div class="nav-link--title">設定</div>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import IconProduct from '@/components/icons/IconProduct.vue'
import IconCategory from '@/components/icons/IconCategory.vue'
import IconFeed from '@/components/icons/IconFeed.vue'
import IconGoldNews from '@/components/icons/IconGoldNews.vue'
import IconRmbrate from '@/components/icons/IconRmbrate.vue'
import IconBranch from '@/components/icons/IconBranch.vue'

export default {
  name: 'Sidebar',

  components: {
    IconProduct,
    IconCategory,
    IconFeed,
    IconGoldNews,
    IconRmbrate,
    IconBranch
  }
}
</script>
